


































































import { Component, Vue } from 'vue-property-decorator';
import { ProductListContent } from '@/types/neuhaus-website/cms/pages/products';
import axios, { APIResponse } from '@/plugins/axios';
import { handleFile } from '@/utils/cms/formhelper';
import AlertModule, { AlertType } from '@/store/modules/alert';

/**
 * CMSAdminProduct component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
*/
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-admin-component-product-list': () => import(
            '@/components/neuhaus-website/cms/admin/product/CMSAdminComponentProductList.vue'
        )
    }
})
export default class CMSAdminProduct extends Vue {
    // variable with form data
    private formData: ProductListContent = {
        id: -1,
        title: 'N/A',
        productCards: []
    }

    // Boolean that defines wheater form is valid or not
    private formValid = false;

    /**
     * Function will be executed on component load
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
    */
    private async created() {
        this.formData = await this.fetchPageContent();
    }

    /**
     * Fetches page content from API and returns the result
     *
     * @returns ProductListContent
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
    */
    private async fetchPageContent(): Promise<ProductListContent> {
        try {
            const response = await axios.get<APIResponse<ProductListContent>>('/cms/pages/products')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.formData;
        }
    }

    /**
     * Resets the form validation
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
    */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves form data
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
    */
    private async saveFormData() {
        for (let i = 0; i < this.formData.productCards.length; i++) {
            const productCard = this.formData.productCards[i];

            handleFile(
                undefined,
                productCard.image.src,
                productCard.image.obj
            ) 
                .then(productResponse => {
                    if (
                        productResponse.data.status !== 'success' || 
                        !productResponse.data.data.fileUrl
                    ) {
                        const errorMessage = this.$t('alerts.error.' + productResponse.data.message).toString();
                        AlertModule.showAlert({ 
                            type: AlertType.ERROR,
                            message: errorMessage 
                        });

                        this.formData.productCards[i].content.imageUrl = '';
                    } else {
                        this.formData.productCards[i].content.imageUrl = productResponse.data.data.fileUrl;
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }

        axios.put<APIResponse>('/cms/pages/products', this.formData)
            .then(response => {
                if (response.data.status === 'success' && response.data.data) {
                    this.resetFormValidation();
                    this.$router.push({ name: 'cmsAdminPages' });
                }
                else if (response.data.status === 'error' && response.data.message) {
                    const errorMessage = this.$t('alerts.errors.' + response.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });
                }
            });
    }
}
